import { Button, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LogoImg from '../assets/logo/cappital.png';
import { Google } from '../assets/svg';
import { ROUTES, TOKEN } from '../common/constants';
import { handleLogin } from '../firebase/api';
import './index.scss';

const { Text } = Typography;

export default function Login() {
  const [loading, setLoading] = useState();
  const history = useHistory();

  const handleLoading = (toggle) => {
    setLoading(toggle);
  };

  const handleGoogleSignIn = async () => {
    handleLogin(handleLoading);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const accessToken = localStorage?.getItem(TOKEN);
    if (accessToken) history?.replace(ROUTES?.DASHBOARD);
  });

  return (
    <div className="login-form">
      <img src={LogoImg} alt="Cappital" width={120} />
      <Button
        icon={<Google />}
        variant="default"
        color="gray"
        className="btn-login"
        size="large"
        onClick={handleGoogleSignIn}
        loading={loading}
      >
        <Text strong>Sign In with Google</Text>
      </Button>
    </div>
  );
}
