import React, { createContext, useEffect, useReducer } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/config';

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return { ...state, user: action?.data };
    case 'SET_USER_DATA':
      return { ...state, userData: action?.data };
    case 'SET_DEMO_DATA':
      return { ...state, demoData: action?.data };
    case 'SET_AUTH':
      return { ...state, isAuthenticated: true };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      dispatch({ type: 'SET_CURRENT_USER', data: user });
    }
  }, [user]);

  const value = {
    state,
    dispatch,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
