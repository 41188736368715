import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import LogoImg from '../../assets/logo/cappital.png';
import { handleLogout } from '../../firebase/api';
import history from '../../historyData';
import { ROUTES } from '../constants';
import './index.scss';

export default function Header() {
  const { state } = useContext(AppContext);

  const handleNavigation = () => {
    history?.push(ROUTES?.DASHBOARD);
  };

  const handleLogoutBtn = () => {
    handleLogout();
  };

  return (
    <header>
      <div className="custom-container">
        <img
          src={LogoImg}
          onClick={handleNavigation}
          className="logo"
          alt="Cappital"
          width={100}
        />
        <Space>
          {state?.user?.uid && (
            <Button icon={<LogoutOutlined />} onClick={handleLogoutBtn}>
              Logout
            </Button>
          )}
          {state?.user?.photoURL ? (
            <Avatar src={state?.user?.photoURL} size="large" />
          ) : (
            <Button shape="circle" size="large">
              {state?.user?.displayName
                ?.split(' ')
                ?.map((word) => word?.[0])
                ?.join('')}
            </Button>
          )}
        </Space>
      </div>
    </header>
  );
}
