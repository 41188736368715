import { StarFilled } from '@ant-design/icons';

export const ROUTES = {
  LOGIN: '/login',
  USER: '/user',
  TEMPLATES: '/templates',
  TEMPLATE_DETAILS: '/template/:id',
  BRD: '/brd',
  PRD: '/prd',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  PROJECT_DETAILS: '/projects/:id',
  HOME: '/',
  NOT_FOUND: '/404',
  PROJECT_NOT_ACTIVE: '/status',
};

export const MESSAGE = {
  NEED_ACCESS: 'You need permission to view this Project.',
  PERMISSION_REQUEST: 'Access requested!',
  ACCESS_REQUESTED:
    'Access requested! Hang tight, your request is being processed. ',
  REQUIRED: 'This field is required',
};

export const COLOR_PALLETS = {
  SUNSET_ORANGE: '#FFA940',
};

export const FILTER_ITEMS = [
  {
    id: 'FEATURED',
    label: 'Featured',
    value: 'featured',
    // using inline style to implement dynamic icon color
    // eslint-disable-next-line react/react-in-jsx-scope
    icon: <StarFilled style={{ color: COLOR_PALLETS?.SUNSET_ORANGE }} />,
  },
  {
    id: 'AI',
    label: 'AI',
    value: 'ai',
    icon: null,
  },
  {
    id: 'MOBILE',
    label: 'Mobile',
    value: 'mobile',
    icon: null,
  },
  {
    id: 'WEB',
    label: 'Web',
    value: 'web',
    icon: null,
  },
  {
    id: 'DESIGN',
    label: 'Design',
    value: 'design',
    icon: null,
  },
  {
    id: 'OTHERS',
    label: 'Others',
    value: '',
    icon: null,
  },
];

export const EXCEPTIONAL_FILTERS_VALUES = 'ai' || 'web' || 'mobile' || 'design';

/* Authentication */
export const TOKEN = 'TOKEN';
