import { Col, Row } from 'antd';
import React from 'react';
import SkeletonCard from './SkeletonCard';

export default function SkeletonWrapper() {
  return (
    <Row gutter={[16, 16]} wrap>
      {new Array(4)?.fill(null)?.map((data, idx) => (
        <Col
          className="gutter-row"
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={6}
        >
          <SkeletonCard />
        </Col>
      ))}
    </Row>
  );
}
