import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthWrapper from './auth/AuthWrapper';
import Login from './auth/Login';
import VerifyUser from './auth/VerifyUser';
import Error404 from './common/Error404';
import NotActive from './common/NotActive';
import { ROUTES } from './common/constants';
import Footer from './common/footer/Footer';
import Header from './common/header/Header';
import history from './historyData';
import Dashboard from './modules/dashboard/Dashboard';
import Projects from './modules/projects/Projects';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path={ROUTES?.LOGIN} exact>
        <Login />
      </Route>
      <Route path={ROUTES?.HOME} exact>
        <Login />
      </Route>
      <AuthWrapper
        path={ROUTES?.DASHBOARD}
        exact
        component={
          <>
            <Header />
            <Dashboard />
            <Footer />
          </>
        }
      />
      <AuthWrapper
        path={ROUTES?.PROJECT_DETAILS}
        exact
        component={
          <VerifyUser>
            <Projects />
          </VerifyUser>
        }
      />
      <Route path={ROUTES?.PROJECT_NOT_ACTIVE}>
        <NotActive />
      </Route>
      <Route path={ROUTES?.EMPTY}>
        <Error404 />
      </Route>
    </Switch>
  </Router>
);
export default Routes;
