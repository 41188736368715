import axios from 'axios';

const sendEmail = (email) =>
  axios
    ?.post(
      process.env.REACT_APP_EMAIL_API,
      {
        email,
      },
      {
        headers: {
          'x-api-key': process.env.REACT_APP_X_API_KEY,
        },
      },
    )
    ?.catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });

export default sendEmail;
