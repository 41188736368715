import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES, TOKEN } from '../common/constants';

const AuthWrapper = ({ component, ...rest }) => {
  // eslint-disable-next-line no-undef
  const isAuth = localStorage?.getItem(TOKEN);
  return (
    <Route
      {...rest}
      render={() =>
        isAuth ? <>{component}</> : <Redirect to={ROUTES?.LOGIN} />
      }
    />
  );
};

export default AuthWrapper;
