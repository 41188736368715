import { Card, Skeleton } from 'antd';
import React from 'react';
import './index.scss';

const { Meta } = Card;

export default function CustomCard() {
  return (
    <>
      <Card className="custom-card" cover={<Skeleton.Image active />}>
        <Meta description={<Skeleton active />} />
      </Card>
    </>
  );
}
