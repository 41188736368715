import { Col, Empty, Row, Tabs } from 'antd';
import { orderBy } from 'lodash';
import React from 'react';
import { EXCEPTIONAL_FILTERS_VALUES, FILTER_ITEMS } from '../constants';
import CustomCard from './CustomCard';

export default function CardsWrapper({ data, isVerified, handleAccess }) {
  const ListCards = ({ keyword }) => {
    if (data?.length > 0) {
      const dataList = orderBy(data, ['createdate'], ['desc'])
        ?.filter((filteredItem) => {
          let validate = false;
          if (keyword === 'featured') {
            validate = filteredItem?.is_featured === true;
          } else if (keyword) {
            validate = filteredItem?.tags
              ?.split(',')
              ?.map((word) => word?.trim()?.toLowerCase())
              ?.includes(keyword);
          } else {
            validate = !filteredItem?.tags
              ?.split(',')
              ?.map((word) => word?.trim()?.toLowerCase())
              ?.includes(EXCEPTIONAL_FILTERS_VALUES);
          }
          return validate;
        })
        ?.map((item) => (
          <Col
            key={item?.id}
            className="gutter-row"
            xs={24}
            sm={12}
            md={12}
            xl={8}
          >
            <CustomCard
              item={item}
              isVerified={isVerified}
              handleAccess={handleAccess}
            />
          </Col>
        ));
      return dataList?.length > 0 ? (
        dataList
      ) : (
        <Col span={24}>
          <Empty description="No Projects, yet!" />
        </Col>
      );
    }
    return (
      <Col span={24}>
        <Empty description="No Projects, yet!" />
      </Col>
    );
  };

  return (
    <Tabs
      defaultActiveKey="FEATURED"
      className="filter-tabs"
      items={FILTER_ITEMS?.map(({ id, label, value, icon }) => ({
        label: (
          <span className="tabs-title">
            {icon}
            {label}
          </span>
        ),
        key: id,
        children: (
          <Row gutter={[16, 16]} wrap>
            <ListCards keyword={value} />
          </Row>
        ),
      }))}
    />
  );
}
