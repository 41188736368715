import { onSnapshot, query, where } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Loader from '../common/Loader';
import RequestVerification from '../common/RequestVerification';
import { ROUTES } from '../common/constants';
import { fetchSingleData, handleError } from '../firebase/api';
import { usersRef } from '../firebase/collection';

const VerifyUser = ({ children }) => {
  const { state, dispatch } = useContext(AppContext);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const history = useHistory();

  const fetchRealTimeData = async (table, condition) => {
    try {
      const searchQuery = query(table, condition);
      onSnapshot(searchQuery, (snapshot) => {
        snapshot?.docChanges()?.forEach(async (change) => {
          if (change?.type === 'added' || change?.type === 'modified') {
            const data = change?.doc?.data();
            if (data) {
              const demoData = await fetchSingleData('demos', id);
              if (demoData) {
                if (demoData?.is_private) {
                  const { isVerified: isAllowed } = data;
                  dispatch({ type: 'SET_USER_DATA', data });
                  dispatch({ type: 'SET_DEMO_DATA', data: demoData });
                  setIsVerified(isAllowed);
                  setLoading(false);
                } else {
                  dispatch({ type: 'SET_USER_DATA', data });
                  dispatch({ type: 'SET_DEMO_DATA', data: demoData });
                  setIsVerified(true);
                  setLoading(false);
                }
              } else {
                history?.push(ROUTES?.NOT_FOUND);
              }
            }
          }
        });
      });
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console?.error(error);
    }
  };

  const fetchUserData = async (uid) => {
    try {
      await fetchRealTimeData(usersRef, where('uid', '==', uid));
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  useEffect(() => {
    if (state?.user?.uid) {
      fetchUserData(state?.user?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.user]);

  if (loading) return <Loader />;
  if (isVerified) return children;
  if (!isVerified) return <RequestVerification />;
};

export default VerifyUser;
