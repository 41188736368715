import { Spin } from 'antd';
import React from 'react';
import './index.scss';

export default function Loader() {
  return (
    <div className="custom-container">
      <div className="request-page antd-loader">
        <Spin />
      </div>
    </div>
  );
}
