import { Button, Result, message } from 'antd';
import { doc, setDoc, where } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { fetchDataWithWhere, handleError } from '../firebase/api';
import { usersRef } from '../firebase/collection';
import { MESSAGE, ROUTES } from './constants';
import sendEmail from './functions';

export default function RequestVerification() {
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleAccess = async () => {
    setLoading(true);
    const uid = state?.userData?.uid;
    const email = state?.userData?.email;
    const payload = state?.userData;
    try {
      const fetchData = await fetchDataWithWhere(
        usersRef,
        where('uid', '==', uid),
      );
      const id = fetchData?.[0]?.id;
      await setDoc(doc(usersRef, id), { ...payload, access: true });
      await sendEmail(email);
      dispatch({ type: 'SET_USER_DATA', data: { ...payload, access: true } });
      message?.success(MESSAGE?.PERMISSION_REQUEST);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleDashboardBtn = () => {
    history?.push(ROUTES?.DASHBOARD);
  };

  return (
    <div className="container">
      <Result
        status="warning"
        title={
          state?.userData?.access
            ? MESSAGE?.ACCESS_REQUESTED
            : MESSAGE?.NEED_ACCESS
        }
        extra={
          state?.userData?.access ? (
            <Button type="default" key="console" onClick={handleDashboardBtn}>
              Go back to Dashboard
            </Button>
          ) : (
            <Button
              type="default"
              key="console"
              onClick={handleAccess}
              loading={loading}
            >
              Request Permission
            </Button>
          )
        }
      />
    </div>
  );
}
