import { Typography } from 'antd';
import React from 'react';
import './index.scss';

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="custom-container">
        <Typography.Paragraph className="text-description">
          © Cappital {new Date()?.getFullYear()}. All Rights Reserved.
        </Typography.Paragraph>
      </div>
    </div>
  );
}
