import { message } from 'antd';
import { signInWithPopup, signOut } from 'firebase/auth';
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { ROUTES, TOKEN } from '../common/constants';
import { usersRef } from './collection';
import { auth, db, googleProvider } from './config';

export const handleError = (error) => {
  message?.error(error?.message);
  // eslint-disable-next-line no-undef
  localStorage?.removeItem(TOKEN);
};
export const fetchData = async (ref) => {
  try {
    const data = await getDocs(ref);
    const firebaseData = data?.docs?.map((docItem) => ({
      ...docItem?.data(),
      id: docItem?.id,
    }));
    return firebaseData;
  } catch (error) {
    handleError(error);
  }
};

export const addData = async (ref, payload) => {
  try {
    const docRef = await addDoc(ref, payload);
    return docRef?.id;
  } catch (error) {
    handleError(error);
  }
};

export const fetchSingleData = async (table, id) => {
  try {
    const docSnap = await getDoc(doc(db, table, id));
    return docSnap?.data();
  } catch (error) {
    handleError(error);
  }
};

export const updateData = async (ref, table, id, payload) => {
  try {
    const prevData = await fetchSingleData(table, id);
    await setDoc(doc(ref, id), { ...prevData, ...payload });
    return true;
  } catch (error) {
    handleError(error);
  }
};

export const fetchDataWithWhere = async (table, condition) => {
  try {
    const data = await getDocs(query(table, condition));
    const firebaseData = data?.docs?.map((docItem) => ({
      ...docItem?.data(),
      id: docItem?.id,
    }));
    return firebaseData;
  } catch (error) {
    handleError(error);
  }
};

export const handleLogin = async (callback) => {
  try {
    callback(true);
    const data = await signInWithPopup(auth, googleProvider);
    if (data?.user) {
      const { displayName, email, uid, photoURL, accessToken } = data?.user;
      const fetchedData = await fetchDataWithWhere(
        usersRef,
        where('uid', '==', uid),
      );

      if (fetchedData?.length === 0) {
        await addData(usersRef, {
          displayName,
          email,
          uid,
          photoURL,
          isVerified: false,
          access: false,
          createdAt: new Date()?.toISOString(),
        });
      }
      callback(false);
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TOKEN, accessToken);
      // eslint-disable-next-line no-undef
      window.location.href = ROUTES?.DASHBOARD;
    }
  } catch (error) {
    handleError(error);
  }
};

export const handleLogout = async () => {
  try {
    await signOut(auth);
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(TOKEN);
    // eslint-disable-next-line no-undef
    window.location.href = ROUTES?.LOGIN;
  } catch (error) {
    handleError(error);
  }
};
