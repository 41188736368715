import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from './constants';

export default function NotActive() {
  const history = useHistory();

  const handleRedirect = (path) => {
    history?.push(path);
  };

  return (
    <div className="container">
      <Result
        title="Project no longer active."
        extra={
          <Button
            type="primary"
            onClick={() => handleRedirect(ROUTES?.DASHBOARD)}
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
}
