import { CaretRightOutlined, LockOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Divider,
  Modal,
  Space,
  Tag,
  Typography,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ExclamationCircleLogo } from '../../assets/svg';
import { COLOR_PALLETS, ROUTES } from '../constants';
import './index.scss';

const { Meta } = Card;

export default function CustomCard({ item, isVerified, handleAccess }) {
  const {
    tags,
    short_description: description,
    title,
    id,
    thumbnail_url: imgUrl,
    logo_url: logoUrl,
    is_private: isPrivate,
    is_featured: isFeatured,
  } = item;

  const isDemoPrivate = isVerified ? false : isPrivate;
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [openRequestedModal, setOpenRequestedModal] = useState(false);
  const { state } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (isVerified) {
      setOpenRequestedModal(false);
    }
  }, [isVerified]);

  const CardFooter = () => (
    <Space>
      <Space size={[0, 8]} wrap direction="vertical">
        <Space className="title-wrapper" direction="vertical">
          <Space align="center" direction="horizontal" className="logo-wrapper">
            {logoUrl && (
              <img
                src={logoUrl?.src}
                alt={logoUrl?.title}
                className="logo-img"
              />
            )}
            <Typography.Title level={3} className="text-title">
              {title}
            </Typography.Title>
          </Space>
          <Space wrap>
            {Array?.isArray(tags)
              ? tags?.map((tag, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tag className="tags-wrapper" key={idx}>
                    {tag}
                  </Tag>
                ))
              : tags?.split(',')?.map((tag, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tag className="tags-wrapper" key={idx}>
                    {tag?.trim()}
                  </Tag>
                ))}
          </Space>
        </Space>
        <Typography.Paragraph
          className="text-description"
          ellipsis={{ rows: 3 }}
        >
          {description}
        </Typography.Paragraph>
      </Space>
    </Space>
  );
  const handleCardButton = () => {
    if (!isDemoPrivate) {
      history?.push(`${ROUTES?.PROJECTS}/${id}`);
    } else if (state?.userData?.access) {
      setOpenRequestedModal(true);
    } else {
      setOpenAccessModal(true);
    }
  };

  const handleOkButton = () => {
    if (handleAccess()) {
      setOpenAccessModal(false);
      setOpenRequestedModal(true);
    }
  };

  const handleDashboardBtn = () => {
    setOpenAccessModal(false);
    setOpenRequestedModal(false);
  };

  if (isFeatured) {
    return (
      <Badge.Ribbon text="Featured" color={COLOR_PALLETS?.SUNSET_ORANGE}>
        <Card
          className="custom-card"
          onClick={handleCardButton}
          cover={
            <>
              {imgUrl && (
                <img
                  src={imgUrl?.src}
                  alt={imgUrl?.title}
                  className="card-image"
                />
              )}
              {isDemoPrivate ? (
                <Button
                  type="primary"
                  shape="circle"
                  className="float-lock"
                  icon={<LockOutlined />}
                />
              ) : (
                <Button
                  type="primary"
                  shape="circle"
                  className="float-lock"
                  icon={<CaretRightOutlined />}
                />
              )}
            </>
          }
        >
          <Meta description={<CardFooter />} />
        </Card>
        <Modal
          title={title || 'Access Modal'}
          open={openAccessModal}
          onOk={handleOkButton}
          okText="Request Access"
          onCancel={() => setOpenAccessModal(false)}
          // using inline style to hide cancel button
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Divider />
          <Typography.Title level={4}>This is a private demo</Typography.Title>
          <Typography.Paragraph>
            Request access to view this demo
          </Typography.Paragraph>
          <Divider />
        </Modal>
        <Modal
          open={openRequestedModal}
          footer={null}
          closeIcon={false}
          onCancel={() => setOpenRequestedModal(false)}
        >
          <Space direction="vertical" align="center" size="middle">
            <ExclamationCircleLogo />
            <Typography.Title level={4} align="center">
              Access requested! Hang tight, your request is being processed.
            </Typography.Title>
            <Button type="default" onClick={handleDashboardBtn}>
              Go back to Dashboard
            </Button>
          </Space>
        </Modal>
      </Badge.Ribbon>
    );
  }
  return (
    <>
      <Card
        className="custom-card"
        onClick={handleCardButton}
        cover={
          <>
            {imgUrl && (
              <img
                src={imgUrl?.src}
                alt={imgUrl?.title}
                className="card-image"
              />
            )}
            {isDemoPrivate ? (
              <Button
                type="primary"
                shape="circle"
                className="float-lock"
                icon={<LockOutlined />}
              />
            ) : (
              <Button
                type="primary"
                shape="circle"
                className="float-lock"
                icon={<CaretRightOutlined />}
              />
            )}
          </>
        }
      >
        <Meta description={<CardFooter />} />
      </Card>
      <Modal
        title={title || 'Access Modal'}
        open={openAccessModal}
        onOk={handleOkButton}
        okText="Request Access"
        onCancel={() => setOpenAccessModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Divider />
        <Typography.Title level={4}>This is a private demo</Typography.Title>
        <Typography.Paragraph>
          Request access to view this demo
        </Typography.Paragraph>
        <Divider />
      </Modal>
      <Modal
        open={openRequestedModal}
        footer={null}
        closeIcon={false}
        onCancel={() => setOpenRequestedModal(false)}
      >
        <Space direction="vertical" align="center" size="middle">
          <ExclamationCircleLogo />
          <Typography.Title level={4} align="center">
            Access requested! Hang tight, your request is being processed.
          </Typography.Title>
          <Button type="default" onClick={handleDashboardBtn}>
            Go back to Dashboard
          </Button>
        </Space>
      </Modal>
    </>
  );
}
